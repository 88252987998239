<template>
  <app-layout>
    <template v-if="!saved">
      <div class="unsubscribe__message">
        Are you sure you want to unsubscribe from reminder emails?
      </div>
      <div class="unsubscribe__radio-list">
        <validation-provider
          name="unsubscribe"
          rules="required"
          v-slot="{ errors }"
        >
          <div class="error-message mb" v-if="errors[0]">{{ errors[0] }}</div>
          <radio-list v-model="unsubscribe" :options="optionList" />
        </validation-provider>
      </div>
      <button
        class="button button_secondary button_md unsubscribe__button"
        @click="submit"
        :disabled="loading"
      >
        <span>Save</span>
      </button>
      <div v-if="error" class="unsubscribe__error text-danger">{{ error }}</div>
    </template>

    <template v-if="isYes">
      <div>
        You have been unsubscribed from reminder emails. You may resubscribe
        from the
        <router-link :to="settings" class="unsubscribe__settings-link"
          >Settings</router-link
        >
        page.
      </div>
    </template>

    <template v-if="isNo">
      <div>
        You will continue to receive reminder emails. You may unsubscribe from
        the
        <router-link :to="settings" class="unsubscribe__settings-link"
          >Settings</router-link
        >
        page.
      </div>
    </template>
  </app-layout>
</template>

<script>
import AppLayout from "@/components/appLayout";
import RadioList from "@/components/ui/radioList";
import logVisiting from "@/mixins/logVisiting";
import { ajaxUnsubscribe } from "@/api";
import { ROUTE_PROFILE_SETTINGS } from "@/constants";

export default {
  name: "UnsubscribePage",
  components: { AppLayout, RadioList },
  mixins: [logVisiting],

  props: {
    token: { type: String },
  },

  data: () => ({
    loading: false,
    error: null,
    settings: { name: ROUTE_PROFILE_SETTINGS },
    optionList: [
      { value: true, text: "Yes" },
      { value: false, text: "No" },
    ],
    unsubscribe: false,
    saved: false,
  }),

  computed: {
    isYes() {
      return this.saved && this.unsubscribe;
    },
    isNo() {
      return this.saved && !this.unsubscribe;
    },
  },

  methods: {
    submit() {
      this.error = null;
      this.loading = true;

      if (!this.unsubscribe) {
        return (this.saved = true);
      }

      ajaxUnsubscribe({ token: this.token })
        .then(() => {
          this.saved = true;
        })
        .catch(() => {
          this.error = "Something went wrong";
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.unsubscribe {
  &__message {
    margin-bottom: 0.5rem;
  }

  &__radio-list {
    margin-bottom: 2rem;
  }

  &__button {
    margin: 0 auto;
  }

  &__error {
    padding-top: 0.5rem;
  }
}
</style>
